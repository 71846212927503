export const logs = [
  // {
  //     title: "offer.janowo.diary.timeline.february",
  //     year: 2023,
  //     pics: [
  //         [
  //         ],
  //         [
  //         ],
  //     ],
  //     alts: [],
  // },
  {
    title: "offer.janowo.diary.timeline.end",
    year: null,
    pics: [
      [
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-109.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-116.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-120.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-127.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-134.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-139.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-141.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-142.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-146.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-150.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-159.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-164.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-169.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-172.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-174.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-175.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-189.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-30-Edit.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-38.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-4.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-40.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-49.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-68.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-6-Edit.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-76.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-87.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-88.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-89.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-90.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-92.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-96.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-97.jpg",
        "/assets/images/janowo-2/diary/2024-end/20240108-janowo-park-II-99.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-109.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-116.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-120.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-127.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-134.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-139.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-141.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-142.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-146.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-150.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-159.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-164.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-169.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-172.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-174.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-175.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-189.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-30-Edit.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-38.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-4.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-40.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-49.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-68.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-6-Edit.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-76.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-87.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-88.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-89.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-90.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-92.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-96.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-97.jpg",
        "/assets/images/janowo-2/diary/2024-end/mini_20240108-janowo-park-II-99.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.november",
    year: 2023,
    pics: [
      [
        "/assets/images/janowo-2/diary/2023-11/20240104_142659.jpg",
        "/assets/images/janowo-2/diary/2023-11/20240104_142708.jpg",
        "/assets/images/janowo-2/diary/2023-11/20240104_142725.jpg",
        "/assets/images/janowo-2/diary/2023-11/20240104_142734.jpg",
        "/assets/images/janowo-2/diary/2023-11/20240104_142759.jpg",
        "/assets/images/janowo-2/diary/2023-11/20240104_142851.jpg",

        "/assets/images/janowo-2/diary/2023-11/20240104_142903.jpg",
        "/assets/images/janowo-2/diary/2023-11/20240104_142923.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2023-11/mini_20240104_142659.jpg",
        "/assets/images/janowo-2/diary/2023-11/mini_20240104_142708.jpg",
        "/assets/images/janowo-2/diary/2023-11/mini_20240104_142725.jpg",
        "/assets/images/janowo-2/diary/2023-11/mini_20240104_142734.jpg",
        "/assets/images/janowo-2/diary/2023-11/mini_20240104_142759.jpg",
        "/assets/images/janowo-2/diary/2023-11/mini_20240104_142851.jpg",

        "/assets/images/janowo-2/diary/2023-11/mini_20240104_142903.jpg",
        "/assets/images/janowo-2/diary/2023-11/mini_20240104_142923.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.october",
    year: 2023,
    pics: [
      [
        "/assets/images/janowo-2/diary/2023-10/20231025_082400.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_082406.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_082558.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_082642.jpg",
        // "/assets/images/janowo-2/diary/2023-10/20231025_082854.jpg",
        // "/assets/images/janowo-2/diary/2023-10/20231025_082903.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_082918.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083015.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083027.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083038.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083047.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083058.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083127.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083204.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083330.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083358.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083431.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083538.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083548.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083559.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083636.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083647.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083712.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083722.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083847.jpg",
        "/assets/images/janowo-2/diary/2023-10/20231025_083904.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_082400.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_082406.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_082558.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_082642.jpg",
        // "/assets/images/janowo-2/diary/2023-10/mini_20231025_082854.jpg",
        // "/assets/images/janowo-2/diary/2023-10/mini_20231025_082903.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_082918.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083015.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083027.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083038.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083047.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083058.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083127.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083204.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083330.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083358.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083431.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083538.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083548.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083559.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083636.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083647.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083712.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083722.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083847.jpg",
        "/assets/images/janowo-2/diary/2023-10/mini_20231025_083904.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.september",
    year: 2023,
    pics: [
      [
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_150814.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_150833.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_150912.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_151048.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_151239.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_151303.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_151355.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_151648.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_151708.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_151745.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_151811.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_151916.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_152027_1.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_152120.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_152205.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_152303.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_152436.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_152445.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_153204.jpg",
        "/assets/images/janowo-2/diary/2023-09/IMG_20230908_153333.jpg",

        "/assets/images/janowo-2/diary/2023-09/20230912_142042.jpg",
        // "/assets/images/janowo-2/diary/2023-09/20230912_142154.jpg",
        "/assets/images/janowo-2/diary/2023-09/20230912_142325.jpg",
        "/assets/images/janowo-2/diary/2023-09/20230912_142448.jpg",
        "/assets/images/janowo-2/diary/2023-09/20230912_142739.jpg",
        "/assets/images/janowo-2/diary/2023-09/20230912_142809.jpg",
        "/assets/images/janowo-2/diary/2023-09/20230912_142947.jpg",
        "/assets/images/janowo-2/diary/2023-09/20230912_143009.jpg",
        "/assets/images/janowo-2/diary/2023-09/20230912_143244.jpg",
        "/assets/images/janowo-2/diary/2023-09/20230912_143414.jpg",
        "/assets/images/janowo-2/diary/2023-09/20230912_143502.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_150814.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_150833.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_150912.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_151048.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_151239.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_151303.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_151355.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_151648.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_151708.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_151745.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_151811.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_151916.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_152027_1.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_152120.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_152205.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_152303.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_152436.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_152445.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_153204.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_IMG_20230908_153333.jpg",

        "/assets/images/janowo-2/diary/2023-09/mini_20230912_142042.jpg",
        // "/assets/images/janowo-2/diary/2023-09/mini_20230912_142154.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_20230912_142325.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_20230912_142448.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_20230912_142739.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_20230912_142809.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_20230912_142947.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_20230912_143009.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_20230912_143244.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_20230912_143414.jpg",
        "/assets/images/janowo-2/diary/2023-09/mini_20230912_143502.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.august",
    year: 2023,
    pics: [
      [
        "/assets/images/janowo-2/diary/2023-08/20230821_153847.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_153907.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_153931.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_153942.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154010.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154120.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154137.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154236.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154310.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154649.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154807.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154831.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154901.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154911.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_154927.jpg",
        "/assets/images/janowo-2/diary/2023-08/20230821_155018.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_153847.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_153907.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_153931.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_153942.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154010.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154120.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154137.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154236.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154310.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154649.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154807.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154831.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154901.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154911.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_154927.jpg",
        "/assets/images/janowo-2/diary/2023-08/mini_20230821_155018.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.july",
    year: 2023,
    pics: [
      [
        "/assets/images/janowo-2/diary/2023-07/20230727_103934.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_104002.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_104055.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_104200.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_104217.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_104333.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_104511.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_104544.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_105202.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_105432.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_110339.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_110807.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_110824.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_110847.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_111008.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_111446.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_111556.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_111607.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_111715.jpg",
        "/assets/images/janowo-2/diary/2023-07/20230727_111740.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_103934.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_104002.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_104055.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_104200.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_104217.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_104333.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_104511.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_104544.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_105202.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_105432.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_110339.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_110807.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_110824.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_110847.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_111008.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_111446.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_111556.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_111607.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_111715.jpg",
        "/assets/images/janowo-2/diary/2023-07/mini_20230727_111740.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.june",
    year: 2023,
    pics: [
      [
        "/assets/images/janowo-2/diary/2023-06/20230628_130414.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_132719.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_132746.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_132959.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_133109.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_133252.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_134404.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_134829.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_134852.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_140214.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_140313.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_140409.jpg",
        "/assets/images/janowo-2/diary/2023-06/20230628_140507.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_130414.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_132719.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_132746.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_132959.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_133109.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_133252.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_134404.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_134829.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_134852.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_140214.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_140313.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_140409.jpg",
        "/assets/images/janowo-2/diary/2023-06/mini_20230628_140507.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.may",
    year: 2023,
    pics: [
      [
        "/assets/images/janowo-2/diary/2023-05/20230524_160542.jpg",
        "/assets/images/janowo-2/diary/2023-05/20230524_161118.jpg",
        "/assets/images/janowo-2/diary/2023-05/20230524_161324.jpg",
        "/assets/images/janowo-2/diary/2023-05/20230524_161634.jpg",
        "/assets/images/janowo-2/diary/2023-05/20230524_162142.jpg",
        "/assets/images/janowo-2/diary/2023-05/20230524_162243.jpg",
        "/assets/images/janowo-2/diary/2023-05/20230524_162656.jpg",
        "/assets/images/janowo-2/diary/2023-05/20230524_162820.jpg",
        "/assets/images/janowo-2/diary/2023-05/20230524_162837.jpg",
        "/assets/images/janowo-2/diary/2023-05/20230524_163029.jpg",
        "/assets/images/janowo-2/diary/2023-05/20230524_163049.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_160542.jpg",
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_161118.jpg",
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_161324.jpg",
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_161634.jpg",
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_162142.jpg",
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_162243.jpg",
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_162656.jpg",
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_162820.jpg",
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_162837.jpg",
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_163029.jpg",
        "/assets/images/janowo-2/diary/2023-05/mini_20230524_163049.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.march",
    year: 2023,
    pics: [
      [
        "/assets/images/janowo-2/diary/2023-03/20230331_122117.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230405_160512.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230405_160603.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230405_160710.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230405_160942.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230405_161146.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230405_161344.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230406_151302.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230406_151313.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230406_151315.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230406_151317.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230406_152600.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230406_152619.jpg",
        "/assets/images/janowo-2/diary/2023-03/20230406_152710.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2023-03/mini_20230331_122117.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230405_160512.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230405_160603.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230405_160710.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230405_160942.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230405_161146.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230405_161344.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230406_151302.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230406_151313.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230406_151315.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230406_151317.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230406_152600.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230406_152619.jpg",
        "/assets/images/janowo-2/diary/2023-03/mini_20230406_152710.jpg",
      ],
    ],
    alts: [],
  },

  {
    title: "offer.janowo.diary.timeline.february",
    year: 2023,
    pics: [
      [
        "/assets/images/janowo-2/diary/2023-02/20230311_101707.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_101722.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_101751.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_101754.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_101758.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_102532.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_102608.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_102642.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_102648.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_102650.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_102655.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_102714.jpg",
        "/assets/images/janowo-2/diary/2023-02/20230311_102722.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_101707.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_101722.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_101751.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_101754.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_101758.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_102532.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_102608.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_102642.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_102648.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_102650.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_102655.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_102714.jpg",
        "/assets/images/janowo-2/diary/2023-02/mini_20230311_102722.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.january",
    year: 2023,
    pics: [
      [
        "/assets/images/janowo-2/diary/2023-01/20230210_141715.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_141737.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_141851.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_141900.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_141931.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_141941.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142002.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142016.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142129.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142208.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142323.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142331.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142406.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142606.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142618.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142715.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_142723.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_143314.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_143318.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_143322.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_143325.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_143726.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_143743.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_143746.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_143802.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_143845.jpg",
        "/assets/images/janowo-2/diary/2023-01/20230210_143849.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230210_144248.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230210_144311.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_141715.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_141737.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_141851.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_141900.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_141931.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_141941.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142002.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142016.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142129.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142208.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142323.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142331.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142406.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142606.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142618.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142715.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_142723.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_143314.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_143318.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_143322.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_143325.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_143726.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_143743.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_143746.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_143802.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_143845.jpg",
        "/assets/images/janowo-2/diary/2023-01/mini_20230210_143849.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230210_144248.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230210_144311.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.december",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-12/20230120_145622.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145626.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145639.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145643.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145648.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145725.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145755.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145758.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145800.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145807.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145818.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145844.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145857.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145918.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145923.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145926.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145928.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145937.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145946.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145954.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150016.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150051.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150054.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150057.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150159.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150204.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150234.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150239.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150242.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150252.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150257.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150303.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150306.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150322.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150324.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150327.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150418.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150425.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150450.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150617.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150633.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-12/20230120_145622_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145626_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145639_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145643_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145648_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145725_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145755_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145758_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145800_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145807_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145818_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145844_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145857_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145918_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145923_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145926_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145928_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145937_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145946_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_145954_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150016_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150051_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150054_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150057_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150159_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150204_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150234_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150239_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150242_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150252_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150257_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150303_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150306_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150322_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150324_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150327_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150418_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150425_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150450_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150617_mini.jpg",
        "/assets/images/janowo-2/diary/2022-12/20230120_150633_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.november",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-11/20221207_145720.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145730.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145735.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145751.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145822.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145840.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145854.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145859.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_154048.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-10.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-11.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-12.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-13.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-14.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-8.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-9.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-11/20221207_145720_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145730_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145735_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145751_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145822_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145840_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145854_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_145859_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/20221207_154048_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-10_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-11_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-12_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-13_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-14_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-8_mini.jpg",
        "/assets/images/janowo-2/diary/2022-11/willbud-rumia-dron-9_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.october",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-10/20221026_102546.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_102639.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_102849.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_102853.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_102903.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_102942.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103402.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103406.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103411.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103414.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103436.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103457.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103500.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_105613.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_105617.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_105620.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-10/20221026_102546_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_102639_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_102849_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_102853_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_102903_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_102942_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103402_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103406_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103411_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103414_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103436_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103457_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_103500_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_105613_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_105617_mini.jpg",
        "/assets/images/janowo-2/diary/2022-10/20221026_105620_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.september",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-09/20221015_094257.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_094353.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_094410.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100147.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100157.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100429.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100500.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100504.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100511.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100523.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100714.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100803.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100849.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_101137.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_103826.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_103850.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_103920.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_104010.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-09/20221015_094257_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_094353_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_094410_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100147_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100157_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100429_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100500_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100504_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100511_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100523_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100714_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100803_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_100849_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_101137_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_103826_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_103850_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_103920_mini.jpg",
        "/assets/images/janowo-2/diary/2022-09/20221015_104010_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.august",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-08/20220914_154943.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155020.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155033.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155055.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155114.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155408.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155928.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160008.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160032.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160131.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160152.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160206.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160242.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_161250.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-08/20220914_154943_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155020_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155033_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155055_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155114_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155408_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_155928_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160008_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160032_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160131_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160152_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160206_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_160242_mini.jpg",
        "/assets/images/janowo-2/diary/2022-08/20220914_161250_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.july",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-07/20220720_155916.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_155921.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160039.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160041.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160105.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160120.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160245.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160308.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160407.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160409.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160707.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160723.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160727.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220729_170605.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220729_170806.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220729_170812.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220729_170820.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220729_170823.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-07/20220720_155916_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_155921_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160039_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160041_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160105_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160120_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160245_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160308_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160407_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160409_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160707_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160723_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220720_160727_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220729_170605_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220729_170806_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220729_170812_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220729_170820_mini.jpg",
        "/assets/images/janowo-2/diary/2022-07/20220729_170823_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.june",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-06/20220630_144515.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_144530.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_144544.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_145319.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_145644.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_145709.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_145805.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_145809.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-06/20220630_144515_mini.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_144530_mini.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_144544_mini.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_145319_mini.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_145644_mini.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_145709_mini.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_145805_mini.jpg",
        "/assets/images/janowo-2/diary/2022-06/20220630_145809_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.may",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-05/20220607_145727.jpg",
        "/assets/images/janowo-2/diary/2022-05/20220607_145746.jpg",
        "/assets/images/janowo-2/diary/2022-05/20220607_145749.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-05/20220607_145727_mini.jpg",
        "/assets/images/janowo-2/diary/2022-05/20220607_145746_mini.jpg",
        "/assets/images/janowo-2/diary/2022-05/20220607_145749_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.april",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-04/20220518_150858.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182235.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182240.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182255.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182302.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182450.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182503.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182513.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182519.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182522.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182524.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182527.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-04/20220518_150858_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182235_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182240_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182255_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182302_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182450_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182503_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182513_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182519_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182522_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182524_mini.jpg",
        "/assets/images/janowo-2/diary/2022-04/20220526_182527_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.march",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-03/20220331_115639.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115651.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115713.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115714.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115736.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115741.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115811.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115817.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115825.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120348.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120353.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120420.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120443.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120518.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120535.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120542.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-03/20220331_115639_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115651_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115713_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115714_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115736_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115741_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115811_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115817_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_115825_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120348_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120353_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120420_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120443_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120518_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120535_mini.jpg",
        "/assets/images/janowo-2/diary/2022-03/20220331_120542_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.february",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-02/20220224_115608.jpg",
        "/assets/images/janowo-2/diary/2022-02/20220224_115653.jpg",
        "/assets/images/janowo-2/diary/2022-02/20220224_115714.jpg",
        "/assets/images/janowo-2/diary/2022-02/20220224_115729.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-02/20220224_115608_mini.jpg",
        "/assets/images/janowo-2/diary/2022-02/20220224_115653_mini.jpg",
        "/assets/images/janowo-2/diary/2022-02/20220224_115714_mini.jpg",
        "/assets/images/janowo-2/diary/2022-02/20220224_115729_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.january",
    year: 2022,
    pics: [
      [
        "/assets/images/janowo-2/diary/2022-01/20220107_133017.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220107_133022.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220107_133109.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220107_133112.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220210_143157.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220210_144711.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220213_124106.jpg",

        "/assets/images/janowo-2/diary/2022-01-2/20220108_093635.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093640.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093650.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093654.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093822.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093824.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093829.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093830.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093831.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093803.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093806.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093807.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093808.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093929.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093930.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093932.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093933.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093936.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093937.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093940.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093941.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093943.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093945.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2022-01/20220107_133017_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220107_133022_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220107_133109_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220107_133112_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220210_143157_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220210_144711_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01/20220213_124106_mini.jpg",

        "/assets/images/janowo-2/diary/2022-01-2/20220108_093635_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093640_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093650_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093654_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093822_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093824_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093829_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093830_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220108_093831_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093803_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093806_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093807_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093808_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093929_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093930_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093932_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093933_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093936_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093937_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093940_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093941_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093943_mini.jpg",
        "/assets/images/janowo-2/diary/2022-01-2/20220212_093945_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.december",
    year: 2021,
    pics: [
      [
        "/assets/images/janowo-2/diary/2021-12/20211207_090356.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090358.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090400.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090401.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090403.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090405.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090407.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211210_093531.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211210_093535.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211223_113955-2.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211211_143308-3.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2021-12/20211207_090356_mini.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090358_mini.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090400_mini.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090401_mini.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090403_mini.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090405_mini.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211207_090407_mini.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211210_093531_mini.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211210_093535_mini.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211223_113955-2_mini.jpg",
        "/assets/images/janowo-2/diary/2021-12/20211211_143308-3_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.november",
    year: 2021,
    pics: [
      [
        "/assets/images/janowo-2/diary/2021-11/20211210_093529.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211210_093537.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211210_093538.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211210_093540.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091449.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091456.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091516.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091517.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091529.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091535.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091537.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091539.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091541.jpg",
      ],
      [
        "/assets/images/janowo-2/diary/2021-11/20211210_093529_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211210_093537_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211210_093538_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211210_093540_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091449_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091456_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091516_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091517_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091529_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091535_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091537_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091539_mini.jpg",
        "/assets/images/janowo-2/diary/2021-11/20211229_091541_mini.jpg",
      ],
    ],
    alts: [],
  },
]
